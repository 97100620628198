* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  color: var(--body-color);
  background-color: var(--body-bg);
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  letter-spacing: -1px;
}

a {
  color: var(--link-color);
  text-decoration: none;

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

figure {
  margin: 0;
}

img {
  display: block;
  max-width: 100%;
  margin: 0 0 1em 0;
  border-radius: var(--border-radius);
}

code,
pre {
  font-family: var(--code-font);
}

code {
  padding: .1em .25em;
  background-color: var(--code-bg);
  border-radius: var(--border-radius);
}

pre {
  overflow: auto;
  padding: 1em;
  margin: 1em 0;

  code {
    padding: 0;
    background-color: inherit;
  }
}

blockquote {
  padding: 0 1em;
  margin: 0 0 1em 0;
  border-left: .25em solid var(--border-color);
  color: var(--body-color-light);
}

table {
  margin: 1em 0;
  width: 100%;
  border: 0 solid var(--border-color);
  border-collapse: collapse;
}

td,
th {
  padding: .25em .5em;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
}

th {
  text-align: left;
}

thead th {
  border-bottom-color: currentColor;
}

mark {
  padding: .15em;
  border-radius: var(--border-radius);
  color: var(--message-color);
  background-color: var(--message-bg);
}

hr {
  position: relative;
  margin: 2em 0;
  border: 0;
  border-top: 1px solid var(--border-color);
}

abbr {
  font-weight: bold;
  text-transform: uppercase;

  &[title] {
    cursor: help;
    border-bottom: 1px dotted var(--border-color);
  }
}

@media (min-width: $md-screen) {
  body {
    font-size: 120%;
  }
}
