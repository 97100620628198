.sidebar {
  color: var(--sidebar-color);
  background: center / cover;

  //background-image: url(/assets/img/logo.svg); 
  //background-position: top left;
  //background-repeat: no-repeat; 
  //background-size: cover;
}

.header {
  width: 100%;
  font-family: var(--body-font);
  text-align: center;

  //background-color: rgba(92, 143, 91, 0.5);

  margin: 1em;
  //border-radius: 3em;

  a {
    color: var(--sidebar-color);
    background-color: rgba(175, 191, 165, 0.8);
    //padding: 0.5em !important;
    //border-radius: 0.2em;
  }
}

.brand-title {
  font-family: var(--brand-title-font);
  font-size: 2.25em;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: -1px;
}

.brand-tagline {
  font-size: 1.2em;
  line-height: 1.2;
  letter-spacing: -1px;
}

.nav {
  margin: 1em 0;
}

.nav li {
    display: inline-block;
}

.nav-item {
  a:hover {
    background-color: transparent;
  }

  .current {
    font-weight: bold;
  }
}

.social-list {
  white-space: normal;
}

.social-item {
  a {
    background-color: transparent;

    &:focus,
    &:hover {
      color: var(--link-color);
      background-color: transparent;
    }
  }
}

@media (min-width: 0px) {
  .header {
    font-size: 70%;
    padding: 0.7em;

    a {
        padding: 0.1em 1.5em !important;
        border-radius: 0.1em;
    }
  }

  .sidebar {
    background-image: url(/assets/img/logo-260px.png); 
    background-position: top left;
    background-repeat: no-repeat; 
    background-size: contain; 
  }

}

@media (min-width: $md-screen) {
  .header {
    font-size: 80%;
    text-align: left;
    position: absolute;
    bottom: 0;
    padding: 1em;

    a {
        padding: 0.1em 0.2em !important;
        border-radius: 0.2em;
    }
  }

  .sidebar {
    background-image: url(/assets/img/logo-schmal.svg); 
    background-position: top left;
    background-repeat: no-repeat; 
    background-size: cover;
  }

  .nav-item a {
    padding-left: 0;
  }

  .social-item {
    a {
      padding-left: 0;
    }
  }
}

@media (min-width: $lg-screen) {
  .header {
    font-size: 100%;
    padding: 2em;

    a {
        padding: 0.1em 0.3em !important;
        border-radius: 0.2em;
    }
  }

  .sidebar {
    background-image: url(/assets/img/logo-schmal.svg); 
    background-position: top left;
    background-repeat: no-repeat; 
    background-size: cover;
  }
}
