.pagination {
  margin-top: 2em;

  .older,
  .newer {
    border: 1px solid var(--border-color);
    padding: 1em;
  }

  .older {
    border-top-left-radius: var(--border-radius);
    border-bottom-left-radius: var(--border-radius);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .newer {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}
